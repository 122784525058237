/* global config */

'use strict';

angular.module('managerApp').directive('leftsidebar', function () {
    return {
        restrict: 'E',
        templateUrl: 'components/left-sidebar/templates/template.html',
        controller: config.isPartnerSession ? 'PartnerLeftSidebarController' : 'DefaultLeftSidebarController'
    };
});
